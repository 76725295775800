import React, { useEffect } from 'react'

const ExploreTheData = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.defer = true
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: '5666503',
          formId: '65a72142-2530-417d-bcbf-e773aec8b180',
          target: '#explore-data',
        })
      }
    })
  }, [])

  return (
    <section className="section mt-6">
      <div className="container">
        <div className="columns px-3 explore-columns">
          <div className="column content is-6 explore-data-column">
            <h1>Explore the data in detail</h1>
            <p>
              You can use the interactive platform to explore the data behind the College Free
              Speech Rankings. View each question asked and the responses by school and student
              characteristics like gender, race, political leanings, and more. Please enter your
              name and email below for instant access to the data.
            </p>
            <div id="explore-data" />
          </div>
          <div className="column is-6 insights-image">
            <img src="/assets/insights.png" alt="Preview" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default ExploreTheData