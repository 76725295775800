import React, { useState } from 'react'
import _ from 'lodash'
import { motion } from 'framer-motion'
import tooltips from '../../Data/tooltips'
import { Link } from 'react-router-dom'
import numberFormat from '../../Utility/numberFormat'

// Styles
import './SchoolOverview.scss'

// Components
import { loader } from '../TextLoaders/TextLoaders'

const SchoolOverview = ({ school, removeSchool }) => {
  const [loadedImage, setLoadedImage] = useState(false)

  const formatNumber = num => {
    if (!num || !_.isFinite(parseFloat(num))) {
      return ''
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  // Create a boolean on whether or not the date is past September 6th, 2023 at 8am EST
  const isAfterDate = () => {
    const currentDate = new Date()
    const compareDate = new Date('September 6, 2023 08:00:00 EST')
    return currentDate > compareDate
  }

  return (
    <div
      className="box px-0 py-0 is-radiusless mr-4 school-overview-wrapper"
      style={{ width: 350, height: 'auto', position: 'relative' }}
    >
      {removeSchool && (
        <motion.button
          initial={{ scale: 0 }}
          animate={{ scale: 1, transition: { delay: 1.5 } }}
          className="delete-button delete is-large"
          onClick={() => removeSchool(school.slug)}
        ></motion.button>
      )}
      <motion.div
        initial={{ height: 0, display: 'none' }}
        animate={
          loadedImage
            ? { height: 90, display: 'block', transition: { delay: 0.3, duration: 0.3 } }
            : { height: 0, display: 'none' }
        }
        className={`map-header has-background-info ${
          window.location.href.includes('compare') ? '' : 'no-mobile'
        }`}
      >
        {/* TODO: Make this component come from API and have a loading state */}
        <img
          alt="School"
          className="school-photo"
          onLoad={() => {
            //console.log('Image Loaded')
            setLoadedImage(true)
          }}
          src={school && school.metadata.campusPhotoURL}
        />
      </motion.div>

      <div className="content px-4 py-4 special-loader">
        {window.location.href.includes('compare') ? (
          <Link to={`/rank/school/${school.slug}`}>
            <loader.h2 className="mb-1 has-underline has-text-weight-medium title is-5" width="80%">
              {school && school.institutionName}
            </loader.h2>
          </Link>
        ) : (
          <loader.h2 className="mb-1 has-text-weight-medium title is-5" width="80%">
            {school && school.institutionName}
          </loader.h2>
        )}

        <loader.p width="50%" className="is-size-7">
          {school && (
            <div className="level nComponent">
              <div className="level-left">
                {window.location.href.includes('compare') ? (
                  <span>{school.metadata.publicOrPrivate} University</span>
                ) : (
                  <span>
                    {school.metadata.undergradType.replace('-', ' ')},{' '}
                    {school.metadata.publicOrPrivate} University
                  </span>
                )}
              </div>
              <div className="level-right">(n = {school.metadata.responseCount} undergrads)</div>
            </div>
          )}
        </loader.p>

        {/* About */}
        <loader.p width="100%" className="level mb-2 mt-4">
          {/* TODO: Find a way to remove linting errors from shorthand Fragments */}
          {school && (
            <>
              <div className="level-left">
                <strong>About</strong>
              </div>
            </>
          )}
        </loader.p>

        {[
          ['Undergrad Enrollment', 'totalUndergradEnrollment', num => formatNumber(num)],
          ['WSJ Ranking', 'wsjRank', num => isAfterDate() ? `#${formatNumber(num)}` : 'TBD'],
          ['Admission Rate', 'admissionsRate', num => `${(+num * 100).toFixed(1)}%`],
          ['Tuition', 'outOfStateTuition', num => `$${formatNumber(num)}`],
        ]
          .filter(
            ([, metadataProperty]) => school && school.metadata && school.metadata[metadataProperty]
          )
          .map(([title, metadataProperty, formatter], i) => (
            <loader.p
              key={i}
              width="100%"
              className={`level mb-1 ${i % 2 === 0 ? 'has-background-white-ter' : ''}`}
            >
              {/* data-tooltip={
                  'The overall ranking is based on rigorous analysis of seven distinct aspects of the free-speech climate on campus for all students surveyed. The ranking compares 208 top colleges in the U.S. Higher scores indicate more open climates for free speech.'
                }
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow has-tooltip-top" */}
              {school && school.metadata.totalUndergradEnrollment && (
                <>
                  <div className={metadataProperty === 'outOfStateTuition' ? 'level-left is-underlined has-tooltip-multiline has-tooltip-arrow has-tooltip-top' : 'level-left'} style={{ opacity: 0.8 }} data-tooltip={metadataProperty === 'outOfStateTuition' && tooltips['tuition']}>
                    {title}
                  </div>
                  <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                    {formatter(school.metadata[metadataProperty])}
                  </div>
                </>
              )}
            </loader.p>
          ))}

        <loader.p width="100%" className={'level mb-1 has-background-white-ter'}>
          {school && school.viewpointRatio && (
            <>
              <div className="level-left" style={{ opacity: 0.8 }}>
                Viewpoint Ratio
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.viewpointRatio}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-1'}>
          {school && school.viewpoint && (
            <>
              <div className="level-left" style={{ opacity: 0.8 }}>
                Majority Viewpoint
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.viewpoint}
              </div>
            </>
          )}
        </loader.p>

        {/* Rankings */}
        <loader.p width="100%" className="level mb-2 mt-4">
          {school && (
            <>
              <div className="level-left">
                <strong>Rankings</strong>
              </div>
            </>
          )}
        </loader.p>

        <loader.p
          width="100%"
          className={`level mb-0 mt-0 ${school && 'has-background-white-ter'}`}
        >
          {school && (
            <>
              <div
                data-tooltip={
                  'The overall ranking is based on rigorous analysis of seven distinct aspects of the free-speech climate on campus for all students surveyed. The ranking compares 208 top colleges in the U.S. Higher scores indicate more open climates for free speech.'
                }
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow has-tooltip-top"
                style={{ opacity: 0.8 }}
              >
                Best Overall
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.rank ? (
                  `#${school.scores.overall.rank}`
                ) : (
                  <span
                    className="has-tooltip-multiline has-tooltip-arrow has-tooltip-top"
                    data-tooltip="A Warning label rating is assigned by FIRE to a private college or university when its policies clearly and consistently state that it prioritizes other values over a commitment to freedom of speech."
                  >
                    <img
                      style={{ width: '1.2rem', borderBottom: '1px solid black' }}
                      src={'/assets/caution.svg'}
                      alt="Warning Logo"
                    />
                  </span>
                )}
              </div>
            </>
          )}
        </loader.p>

        {/* Overall Score */}

        <loader.p width="100%" className="level mb-2 mt-4">
          {school && (
            <>
              <div className="level-left">
                <strong>Overall</strong>
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className="level mb-0 mt-0">
          {school && (
            <>
              <div
                data-tooltip={tooltips.overall}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Overall Score
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.overall && school.scores.overall.overall.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.comfortRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Comfort Expressing Ideas
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.comfortRank &&
                  numberFormat(school.scores.overall.comfortRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.disruptiveConductRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Disruptive Conduct
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.disruptiveConductRank &&
                  numberFormat(school.scores.overall.disruptiveConductRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.opennessRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Openness
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.opennessRank &&
                  numberFormat(school.scores.overall.opennessRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0 '}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.adminSupportRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Administrative Support
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.adminSupportRank &&
                  numberFormat(school.scores.overall.adminSupportRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>
        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.toleranceRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Tolerance For Speakers (Combined)
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.toleranceRank &&
                  numberFormat(school.scores.overall.toleranceRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>
        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.toleranceLiberalsRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Tolerance For Liberal Speakers
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.toleranceLiberalsRank &&
                  numberFormat(school.scores.overall.toleranceLiberalsRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>
        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.toleranceConservativesRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Tolerance For Conservative Speakers
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.toleranceConservativesRank &&
                  numberFormat(school.scores.overall.toleranceConservativesRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.toleranceDifferenceRank}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Tolerance Difference
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.toleranceDifferenceRank &&
                  numberFormat(school.scores.overall.toleranceDifferenceRank.toFixed(0))}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.speechClimate}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Speech Climate
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.speechClimate && school.scores.overall.speechClimate}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.speechCode}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Speech Code
              </div>
              <div className={'level-right'} style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.speechCode && school.scores.speechCode === 'green' && 'Green'}
                {school.scores.speechCode && school.scores.speechCode === 'yellow' && 'Yellow'}
                {school.scores.speechCode && school.scores.speechCode === 'red' && 'Red'}
                {school.scores.speechCode && school.scores.speechCode === 'warning' && 'Warning'}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.supportedStudents}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Student Support
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.supportedStudents &&
                  school.scores.overall.supportedStudents.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.sanctionedStudents}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Student Sanctions
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.sanctionedStudents &&
                  school.scores.overall.sanctionedStudents.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.supportedStudentGroups}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Student Group Support
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.supportedStudentGroups &&
                  school.scores.overall.supportedStudentGroups.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.sanctionedStudentGroups}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Student Group Sanctions
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.sanctionedStudentGroups &&
                  school.scores.overall.sanctionedStudentGroups.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.supportedScholars}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Scholar Support
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.supportedScholars &&
                  school.scores.overall.supportedScholars.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.sanctionedScholars}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Scholar Sanctions
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.sanctionedScholars &&
                  school.scores.overall.sanctionedScholars.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={`level mb-0 ${school && 'has-background-white-ter'}`}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.supportedSpeakers}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Speaker Support
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.supportedSpeakers &&
                  school.scores.overall.supportedSpeakers.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>

        <loader.p width="100%" className={'level mb-0'}>
          {school && (
            <>
              <div
                data-tooltip={tooltips.disinvitations}
                className="level-left is-underlined has-tooltip-multiline has-tooltip-arrow"
                style={{ opacity: 0.8 }}
              >
                Speaker Disinvitations
              </div>
              <div className="level-right" style={{ minWidth: 50, textAlign: 'right' }}>
                {school.scores.overall.disinvitations &&
                  school.scores.overall.disinvitations.toFixed(1)}
              </div>
            </>
          )}
        </loader.p>
      </div>
    </div>
  )
}

export default SchoolOverview
