let prefix

//prefix = 'https://dartmouthpulsestaging.herokuapp.com'
//prefix = 'https://env-prod-pulse-1.aws.collegepulse.com'
//prefix = 'https://fire-dashboard-api.herokuapp.com'
prefix = 'https://speechapi.collegepulse.com'
//prefix = 'fire-dashboard-api.herokuapp.com'
//prefix = 'https://dartmouthpulse.herokuapp.com'
//prefix = 'http://localhost:3001'

export default prefix
