import React, { useEffect } from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import ReactGA from 'react-ga'

// Components
import MainLayout from './Layout/MainLayout'

// Styles
import './Styles/Main.scss'

// Pages
import Rank from './Pages/Rank'
import School from './Pages/School'
import Landing from './Pages/Landing'
import Compare from './Pages/Compare'
import Methodology from './Pages/Methodology'
import ExploreTheData from './Pages/ExploreTheData'
import ViewData from './Pages/ViewData'

ReactGA.initialize('UA-121938112-4') // Add your ID

const usePageViews = () => {
  let location = useLocation()
  useEffect(() => {
    ReactGA.set({ page: location.pathname })
    ReactGA.pageview(location.pathname)

    const script = document.createElement('script')
    script.src = 'https://js.hsforms.net/forms/v2.js'
    script.defer = true
    document.body.appendChild(script)

    script.addEventListener('load', () => {
      if (window.hbspt && document.querySelector('#hubspot_form').childElementCount === 0) {
        window.hbspt.forms.create({
          portalId: '5666503',
          formId: 'de9b91ce-3dc7-4497-abc0-5d9b60d2f814',
          target: '#hubspot_form',
        })
      }
    })
  }, [location])
}

const App = () => {
  usePageViews()

  return (
    <Switch>
      <Route exact path="/">
        <div>
          {/*
            Hero
            As Seen On
            Our Approach
            */}
          <Landing />
        </div>
      </Route>
      <MainLayout>
        <Switch>
          <Route exact path="/view-data/">
            <ViewData />
          </Route>

          <Route exact path="/rank/">
            {/*
              Nav
              Sidebar Sort
              Table
              */}
            <Rank />
          </Route>
          <Route path="/rank/explore">
            {/*
              Nav
              Sidebar Sort
              Table
              */}
            <ExploreTheData />
          </Route>
          <Route path="/rank/school/:schoolID">
            {/*
              Nav
              School Overview Component
              School Description + social
              Quotes
              */}
            <div>
              <School />
            </div>
          </Route>
          <Route path="/rank/map">
            {/*
              Nav
              Sidebar Sort
              UE Map
              */}
            Map
          </Route>
          <Route path="/rank/map/:schoolID">
            {/*
              Nav
              School Overview Component
              UE Map
              */}
            <School />
          </Route>
          <Route path="/rank/compare">
            <Compare />
          </Route>
          <Route path="/rank/methodology">
            <Methodology />
          </Route>
          <Route
            path="*"
            exact
            render={() => (
              <Redirect
                to={{
                  pathname: '/',
                }}
              />
            )}
          />
        </Switch>
      </MainLayout>
    </Switch>
  )
}

export default App

// export default () => {
//   return (
//     <div
//       style={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         backgroundColor: '#00000020',
//         height: '100vh',
//         overflow: 'hidden!important',
//       }}
//     >
//       <div
//         className="p-5"
//         style={{
//           backgroundColor: 'white',
//           borderRadius: 6,
//           boxShadow: '0 0 10px rgba(0,0,0,0.2)',
//         }}
//         id="card"
//       >
//         <div
//           className="logos is-relative flex"
//           style={{ justifyContent: 'center', paddingRight: '2rem' }}
//         >
//           <a target="_blank" rel="noreferrer" href="https://www.collegepulse.com">
//             <img src="/assets/cp.svg" alt="College Pulse" width="170" className="ml-4 logo-cp" />
//           </a>

//           <a target="_blank" rel="noreferrer" href="https://www.thefire.org/">
//             <img
//               src="/assets/fire.svg"
//               alt="Fire Organization"
//               width="80"
//               className="ml-6 logo-fire"
//               style={{ marginTop: -2 }}
//             />
//           </a>
//         </div>
//         <div
//           style={{ height: 2, backgroundColor: 'gray', marginTop: '1rem', marginBottom: '1rem' }}
//         ></div>
//         <div>
//           <strong>We'll be back soon!</strong>
//           <br />
//           We're launching this years College Free Speech dashboard. Check back soon!
//         </div>
//       </div>
//     </div>
//   )
// }
