/* eslint-disable quotes */

const topQuotes = [
  {
    school: 'Columbia University',
    quote: 'I feel I can not express my opinions on campus despite having very centrist opinions as there is a culture of political extremes with no middle ground. If you at all disagree then you will be shunned and harassed',
    graduationYear: '2024',
  },
  {
    school: 'Cornell University',
    quote: 'On a daily basis, where I am worried that I\'ll be cancelled for saying something politically incorrect.',
    graduationYear: '2025',
  },
  {
    school: 'Georgetown University',
    quote: 'I normally keep to myself about things I do in ROTC. One time when I brought it up a girl in the discussion group asked why I support weapons training',
    graduationYear: '2023',
  },
  {
    school: 'Harvard University',
    quote: 'I often avoid posting controversial takes on social media tied to my name because I am afraid that they might be misconstrued by my classmates or admin.',
    graduationYear: '2024',
  },
  {
    school: 'Howard University',
    quote: 'In some discussion based classes I feel like I have to agree with the prevailing sentiment.',
    graduationYear: '2023',
  },
  {
    school: 'Massachusetts Institute of Technology',
    quote: 'Every day. People are so polarized these days that all it takes is one unpopular opinion to ruin your career or prevent you from having any friends.',
    graduationYear: '2023',
  },
  {
    school: 'Michigan Technological University',
    quote: 'In class people know my name and I had an unpopular opinion and I didn\'t want it linked back to me so that people could attack me online.',
    graduationYear: '2023',
  },
  {
    school: 'New York University',
    quote: 'I feel like New York University cares more about identifying pronouns and recognizing racial differences than actually discussing the content in class. If I were to speak up and ask to get to the coursework, I would be severely judged and deemed unsympathetic.',
    graduationYear: '2023',
  },
  {
    school: 'Princeton University',
    quote: 'There have been times when I want to bring up the disparities that other marginalized people also face and how its nuanced, yet fear that it would be taken the wrong way.',
    graduationYear: '2025',
  },
  {
    school: 'Stanford University',
    quote: 'Sometimes I would kind of agree with a social media post but I would be encouraged to unfollow them for their views.',
    graduationYear: '2026',
  },
]

export default topQuotes
