const schoolTotalCountData = {
  surveyTitle: 'Campus Climate 2023',
  surveyId: '63b57b40fb0cc80019fb26cc',
  table: [
    [
      {
        value: 'total responses',
        count: 53980,
      },
    ],
    [
      {
        unitId: 164465,
        value: 'Amherst College',
        count: 133,
      },
      {
        unitId: 197869,
        value: 'Appalachian State University',
        count: 119,
      },
      {
        unitId: 104151,
        value: 'Arizona State University',
        count: 295,
      },
      {
        unitId: 106458,
        value: 'Arkansas State University',
        count: 141,
      },
      {
        unitId: 100858,
        value: 'Auburn University',
        count: 272,
      },
      {
        unitId: 189088,
        value: 'Bard College',
        count: 97,
      },
      {
        unitId: 189097,
        value: 'Barnard College',
        count: 139,
      },
      {
        unitId: 160977,
        value: 'Bates College',
        count: 163,
      },
      {
        unitId: 223232,
        value: 'Baylor University',
        count: 260,
      },
      {
        unitId: 156295,
        value: 'Berea College',
        count: 191,
      },
      {
        unitId: 196079,
        value: 'Binghamton University',
        count: 302,
      },
      {
        unitId: 142115,
        value: 'Boise State University',
        count: 206,
      },
      {
        unitId: 164924,
        value: 'Boston College',
        count: 103,
      },
      {
        unitId: 164988,
        value: 'Boston University',
        count: 237,
      },
      {
        unitId: 161004,
        value: 'Bowdoin College',
        count: 190,
      },
      {
        unitId: 201441,
        value: 'Bowling Green State University',
        count: 260,
      },
      {
        unitId: 165015,
        value: 'Brandeis University',
        count: 104,
      },
      {
        unitId: 230038,
        value: 'Brigham Young University',
        count: 288,
      },
      {
        unitId: 217156,
        value: 'Brown University',
        count: 156,
      },
      {
        unitId: 211291,
        value: 'Bucknell University',
        count: 276,
      },
      {
        unitId: 110404,
        value: 'California Institute of Technology',
        count: 113,
      },
      {
        unitId: 110422,
        value: 'California Polytechnic State University',
        count: 267,
      },
      {
        unitId: 110556,
        value: 'California State University, Fresno',
        count: 88,
      },
      {
        unitId: 110592,
        value: 'California State University, Los Angeles',
        count: 151,
      },
      {
        unitId: 173258,
        value: 'Carleton College',
        count: 135,
      },
      {
        unitId: 211440,
        value: 'Carnegie Mellon University',
        count: 209,
      },
      {
        unitId: 201645,
        value: 'Case Western Reserve University',
        count: 223,
      },
      {
        unitId: 169248,
        value: 'Central Michigan University',
        count: 278,
      },
      {
        unitId: 111948,
        value: 'Chapman University',
        count: 257,
      },
      {
        unitId: 112260,
        value: 'Claremont McKenna College',
        count: 176,
      },
      {
        unitId: 165334,
        value: 'Clark University',
        count: 198,
      },
      {
        unitId: 190044,
        value: 'Clarkson University',
        count: 60,
      },
      {
        unitId: 217882,
        value: 'Clemson University',
        count: 269,
      },
      {
        unitId: 161086,
        value: 'Colby College',
        count: 177,
      },
      {
        unitId: 190099,
        value: 'Colgate University',
        count: 109,
      },
      {
        unitId: 217819,
        value: 'College of Charleston',
        count: 131,
      },
      {
        unitId: 126678,
        value: 'Colorado College',
        count: 159,
      },
      {
        unitId: 126775,
        value: 'Colorado School of Mines',
        count: 89,
      },
      {
        unitId: 126818,
        value: 'Colorado State University',
        count: 314,
      },
      {
        unitId: 190150,
        value: 'Columbia University',
        count: 300,
      },
      {
        unitId: 128902,
        value: 'Connecticut College',
        count: 120,
      },
      {
        unitId: 190415,
        value: 'Cornell University',
        count: 315,
      },
      {
        unitId: 181002,
        value: 'Creighton University',
        count: 251,
      },
      {
        unitId: 182670,
        value: 'Dartmouth College',
        count: 256,
      },
      {
        unitId: 198385,
        value: 'Davidson College',
        count: 144,
      },
      {
        unitId: 202523,
        value: 'Denison University',
        count: 107,
      },
      {
        unitId: 144740,
        value: 'DePaul University',
        count: 252,
      },
      {
        unitId: 150400,
        value: 'DePauw University',
        count: 189,
      },
      {
        unitId: 212054,
        value: 'Drexel University',
        count: 290,
      },
      {
        unitId: 198419,
        value: 'Duke University',
        count: 205,
      },
      {
        unitId: 212106,
        value: 'Duquesne University',
        count: 257,
      },
      {
        unitId: 198464,
        value: 'East Carolina University',
        count: 279,
      },
      {
        unitId: 156620,
        value: 'Eastern Kentucky University',
        count: 278,
      },
      {
        unitId: 169798,
        value: 'Eastern Michigan University',
        count: 181,
      },
      {
        unitId: 139658,
        value: 'Emory University',
        count: 260,
      },
      {
        unitId: 133951,
        value: 'Florida International University',
        count: 295,
      },
      {
        unitId: 134097,
        value: 'Florida State University',
        count: 303,
      },
      {
        unitId: 191241,
        value: 'Fordham University',
        count: 170,
      },
      {
        unitId: 212577,
        value: 'Franklin and Marshall College',
        count: 39,
      },
      {
        unitId: 218070,
        value: 'Furman University',
        count: 238,
      },
      {
        unitId: 232186,
        value: 'George Mason University',
        count: 214,
      },
      {
        unitId: 131469,
        value: 'George Washington University',
        count: 175,
      },
      {
        unitId: 131496,
        value: 'Georgetown University',
        count: 143,
      },
      {
        unitId: 139755,
        value: 'Georgia Institute of Technology',
        count: 284,
      },
      {
        unitId: 139940,
        value: 'Georgia State University',
        count: 259,
      },
      {
        unitId: 212674,
        value: 'Gettysburg College',
        count: 183,
      },
      {
        unitId: 153384,
        value: 'Grinnell College',
        count: 108,
      },
      {
        unitId: 191515,
        value: 'Hamilton College',
        count: 111,
      },
      {
        unitId: 166027,
        value: 'Harvard University',
        count: 209,
      },
      {
        unitId: 115409,
        value: 'Harvey Mudd College',
        count: 94,
      },
      {
        unitId: 212911,
        value: 'Haverford College',
        count: 112,
      },
      {
        unitId: 170286,
        value: 'Hillsdale College',
        count: 185,
      },
      {
        unitId: 131520,
        value: 'Howard University',
        count: 250,
      },
      {
        unitId: 145725,
        value: 'Illinois Institute of Technology',
        count: 101,
      },
      {
        unitId: 145813,
        value: 'Illinois State University',
        count: 507,
      },
      {
        unitId: 151351,
        value: 'Indiana University',
        count: 426,
      },
      {
        unitId: 151111,
        value: 'Indiana University - Purdue University',
        count: 160,
      },
      {
        unitId: 153603,
        value: 'Iowa State University',
        count: 152,
      },
      {
        unitId: 232423,
        value: 'James Madison University',
        count: 256,
      },
      {
        unitId: 162928,
        value: 'Johns Hopkins University',
        count: 253,
      },
      {
        unitId: 162928,
        value: 'Kansas State University',
        count: 294,
      },
      {
        unitId: 203517,
        value: 'Kent State University',
        count: 199,
      },
      {
        unitId: 203535,
        value: 'Kenyon College',
        count: 115,
      },
      {
        unitId: 146427,
        value: 'Knox College',
        count: 105,
      },
      {
        unitId: 213385,
        value: 'Lafayette College',
        count: 95,
      },
      {
        unitId: 213543,
        value: 'Lehigh University',
        count: 129,
      },
      {
        unitId: 232557,
        value: 'Liberty University',
        count: 265,
      },
      {
        unitId: 159391,
        value: 'Louisiana State University',
        count: 266,
      },
      {
        unitId: 146719,
        value: 'Loyola University, Chicago',
        count: 257,
      },
      {
        unitId: 173902,
        value: 'Macalester College',
        count: 251,
      },
      {
        unitId: 239105,
        value: 'Marquette University',
        count: 237,
      },
      {
        unitId: 166683,
        value: 'Massachusetts Institute of Technology',
        count: 254,
      },
      {
        unitId: 204024,
        value: 'Miami University',
        count: 254,
      },
      {
        unitId: 171100,
        value: 'Michigan State University',
        count: 281,
      },
      {
        unitId: 171128,
        value: 'Michigan Technological University',
        count: 135,
      },
      {
        unitId: 230959,
        value: 'Middlebury College',
        count: 214,
      },
      {
        unitId: 176080,
        value: 'Mississippi State University',
        count: 253,
      },
      {
        unitId: 179566,
        value: 'Missouri State University',
        count: 293,
      },
      {
        unitId: 180461,
        value: 'Montana State University',
        count: 110,
      },
      {
        unitId: 185590,
        value: 'Montclair State University',
        count: 128,
      },
      {
        unitId: 166939,
        value: 'Mount Holyoke College',
        count: 101,
      },
      {
        unitId: 185828,
        value: 'New Jersey Institute of Technology',
        count: 101,
      },
      {
        unitId: 188030,
        value: 'New Mexico State University',
        count: 151,
      },
      {
        unitId: 193900,
        value: 'New York University',
        count: 265,
      },
      {
        unitId: 199193,
        value: 'North Carolina State University',
        count: 139,
      },
      {
        unitId: 200332,
        value: 'North Dakota State University',
        count: 294,
      },
      {
        unitId: 167358,
        value: 'Northeastern University',
        count: 259,
      },
      {
        unitId: 105330,
        value: 'Northern Arizona University',
        count: 209,
      },
      {
        unitId: 147767,
        value: 'Northwestern University',
        count: 253,
      },
      {
        unitId: 204501,
        value: 'Oberlin College',
        count: 134,
      },
      {
        unitId: 120254,
        value: 'Occidental College',
        count: 103,
      },
      {
        unitId: 204796,
        value: 'Ohio State University',
        count: 327,
      },
      {
        unitId: 204857,
        value: 'Ohio University',
        count: 258,
      },
      {
        unitId: 207388,
        value: 'Oklahoma State University',
        count: 278,
      },
      {
        unitId: 209542,
        value: 'Oregon State University',
        count: 256,
      },
      {
        unitId: 214777,
        value: 'Pennsylvania State University',
        count: 320,
      },
      {
        unitId: 121150,
        value: 'Pepperdine University',
        count: 142,
      },
      {
        unitId: 121257,
        value: 'Pitzer College',
        count: 108,
      },
      {
        unitId: 121345,
        value: 'Pomona College',
        count: 104,
      },
      {
        unitId: 209807,
        value: 'Portland State University',
        count: 94,
      },
      {
        unitId: 186131,
        value: 'Princeton University',
        count: 250,
      },
      {
        unitId: 243780,
        value: 'Purdue University',
        count: 288,
      },
      {
        unitId: 194824,
        value: 'Rensselaer Polytechnic Institute',
        count: 122,
      },
      {
        unitId: 227757,
        value: 'Rice University',
        count: 269,
      },
      {
        unitId: 184782,
        value: 'Rowan University',
        count: 137,
      },
      {
        unitId: 186380,
        value: 'Rutgers University',
        count: 281,
      },
      {
        unitId: 179159,
        value: 'Saint Louis University',
        count: 259,
      },
      {
        unitId: 122409,
        value: 'San Diego State University',
        count: 252,
      },
      {
        unitId: 122755,
        value: 'San Jose State University',
        count: 276,
      },
      {
        unitId: 122931,
        value: 'Santa Clara University',
        count: 153,
      },
      {
        unitId: 123165,
        value: 'Scripps College',
        count: 88,
      },
      {
        unitId: 195526,
        value: 'Skidmore College',
        count: 143,
      },
      {
        unitId: 167835,
        value: 'Smith College',
        count: 92,
      },
      {
        unitId: 149222,
        value: 'Southern Illinois University, Carbondale',
        count: 197,
      },
      {
        unitId: 149231,
        value: 'Southern Illinois University, Edwardsville',
        count: 291,
      },
      {
        unitId: 228246,
        value: 'Southern Methodist University',
        count: 251,
      },
      {
        unitId: 243744,
        value: 'Stanford University',
        count: 284,
      },
      {
        unitId: 186867,
        value: 'Stevens Institute of Technology',
        count: 103,
      },
      {
        unitId: 196097,
        value: 'Stony Brook University',
        count: 267,
      },
      {
        unitId: 196060,
        value: 'SUNY at Albany',
        count: 177,
      },
      {
        unitId: 196167,
        value: 'SUNY College at Geneseo',
        count: 84,
      },
      {
        unitId: 216287,
        value: 'Swarthmore College',
        count: 275,
      },
      {
        unitId: 196413,
        value: 'Syracuse University',
        count: 165,
      },
      {
        unitId: 216339,
        value: 'Temple University',
        count: 268,
      },
      {
        unitId: 228723,
        value: 'Texas A&M University',
        count: 260,
      },
      {
        unitId: 228459,
        value: 'Texas State University',
        count: 205,
      },
      {
        unitId: 229115,
        value: 'Texas Tech University',
        count: 253,
      },
      {
        unitId: 231624,
        value: 'The College of William & Mary',
        count: 109,
      },
      {
        unitId: 164076,
        value: 'Towson University',
        count: 114,
      },
      {
        unitId: 164076,
        value: 'Trinity College',
        count: 248,
      },
      {
        unitId: 168148,
        value: 'Tufts University',
        count: 262,
      },
      {
        unitId: 168148,
        value: 'Tulane University',
        count: 264,
      },
      {
        unitId: 196088,
        value: 'University at Buffalo',
        count: 276,
      },
      {
        unitId: 100663,
        value: 'University of Alabama, Birmingham',
        count: 253,
      },
      {
        unitId: 100706,
        value: 'University of Alabama, Huntsville',
        count: 128,
      },
      {
        unitId: 100751,
        value: 'University of Alabama, Tuscaloosa',
        count: 251,
      },
      {
        unitId: 102553,
        value: 'University of Alaska',
        count: 100,
      },
      {
        unitId: 104179,
        value: 'University of Arizona',
        count: 273,
      },
      {
        unitId: 106397,
        value: 'University of Arkansas',
        count: 294,
      },
      {
        unitId: 110635,
        value: 'University of California, Berkeley',
        count: 253,
      },
      {
        unitId: 110644,
        value: 'University of California, Davis',
        count: 252,
      },
      {
        unitId: 110653,
        value: 'University of California, Irvine',
        count: 290,
      },
      {
        unitId: 110662,
        value: 'University of California, Los Angeles',
        count: 275,
      },
      {
        unitId: 445188,
        value: 'University of California, Merced',
        count: 250,
      },
      {
        unitId: 110671,
        value: 'University of California, Riverside',
        count: 265,
      },
      {
        unitId: 110680,
        value: 'University of California, San Diego',
        count: 342,
      },
      {
        unitId: 110705,
        value: 'University of California, Santa Barbara',
        count: 251,
      },
      {
        unitId: 110714,
        value: 'University of California, Santa Cruz',
        count: 296,
      },
      {
        unitId: 132903,
        value: 'University of Central Florida',
        count: 261,
      },
      {
        unitId: 144050,
        value: 'University of Chicago',
        count: 274,
      },
      {
        unitId: 201885,
        value: 'University of Cincinnati',
        count: 267,
      },
      {
        unitId: 126614,
        value: 'University of Colorado, Boulder',
        count: 267,
      },
      {
        unitId: 126562,
        value: 'University of Colorado, Denver',
        count: 323,
      },
      {
        unitId: 129020,
        value: 'University of Connecticut',
        count: 279,
      },
      {
        unitId: 202480,
        value: 'University of Dayton',
        count: 251,
      },
      {
        unitId: 130943,
        value: 'University of Delaware',
        count: 192,
      },
      {
        unitId: 127060,
        value: 'University of Denver',
        count: 105,
      },
      {
        unitId: 134130,
        value: 'University of Florida',
        count: 260,
      },
      {
        unitId: 139959,
        value: 'University of Georgia',
        count: 279,
      },
      {
        unitId: 141574,
        value: 'University of Hawaii',
        count: 112,
      },
      {
        unitId: 225511,
        value: 'University of Houston',
        count: 277,
      },
      {
        unitId: 142285,
        value: 'University of Idaho',
        count: 124,
      },
      {
        unitId: 145600,
        value: 'University of Illinois, Chicago',
        count: 265,
      },
      {
        unitId: 145637,
        value: 'University of Illinois, Urbana-Champaign',
        count: 288,
      },
      {
        unitId: 153658,
        value: 'University of Iowa',
        count: 256,
      },
      {
        unitId: 155317,
        value: 'University of Kansas',
        count: 252,
      },
      {
        unitId: 157085,
        value: 'University of Kentucky',
        count: 254,
      },
      {
        unitId: 157289,
        value: 'University of Louisville',
        count: 292,
      },
      {
        unitId: 161253,
        value: 'University of Maine',
        count: 101,
      },
      {
        unitId: 163286,
        value: 'University of Maryland',
        count: 237,
      },
      {
        unitId: 166629,
        value: 'University of Massachusetts',
        count: 271,
      },
      {
        unitId: 220862,
        value: 'University of Memphis',
        count: 389,
      },
      {
        unitId: 135726,
        value: 'University of Miami',
        count: 266,
      },
      {
        unitId: 170976,
        value: 'University of Michigan',
        count: 270,
      },
      {
        unitId: 174066,
        value: 'University of Minnesota',
        count: 254,
      },
      {
        unitId: 174066,
        value: 'University of Mississippi',
        count: 116,
      },
      {
        unitId: 178396,
        value: 'University of Missouri, Columbia',
        count: 261,
      },
      {
        unitId: 178402,
        value: 'University of Missouri, Kansas City',
        count: 298,
      },
      {
        unitId: 178420,
        value: 'University of Missouri, St. Louis',
        count: 289,
      },
      {
        unitId: 181464,
        value: 'University of Nebraska',
        count: 258,
      },
      {
        unitId: 182281,
        value: 'University of Nevada, Las Vegas',
        count: 250,
      },
      {
        unitId: 182290,
        value: 'University of Nevada, Reno',
        count: 160,
      },
      {
        unitId: 183044,
        value: 'University of New Hampshire',
        count: 257,
      },
      {
        unitId: 187985,
        value: 'University of New Mexico',
        count: 113,
      },
      {
        unitId: 199120,
        value: 'University of North Carolina, Chapel Hill',
        count: 259,
      },
      {
        unitId: 199139,
        value: 'University of North Carolina, Charlotte',
        count: 255,
      },
      {
        unitId: 199148,
        value: 'University of North Carolina, Greensboro',
        count: 190,
      },
      {
        unitId: 227216,
        value: 'University of North Texas',
        count: 293,
      },
      {
        unitId: 152080,
        value: 'University of Notre Dame',
        count: 126,
      },
      {
        unitId: 207500,
        value: 'University of Oklahoma',
        count: 276,
      },
      {
        unitId: 209551,
        value: 'University of Oregon',
        count: 245,
      },
      {
        unitId: 215062,
        value: 'University of Pennsylvania',
        count: 253,
      },
      {
        unitId: 215293,
        value: 'University of Pittsburgh',
        count: 283,
      },
      {
        unitId: 217484,
        value: 'University of Rhode Island',
        count: 260,
      },
      {
        unitId: 195030,
        value: 'University of Rochester',
        count: 143,
      },
      {
        unitId: 122612,
        value: 'University of San Francisco',
        count: 133,
      },
      {
        unitId: 218663,
        value: 'University of South Carolina',
        count: 255,
      },
      {
        unitId: 137351,
        value: 'University of South Florida',
        count: 262,
      },
      {
        unitId: 123961,
        value: 'University of Southern California',
        count: 307,
      },
      {
        unitId: 221759,
        value: 'University of Tennessee',
        count: 277,
      },
      {
        unitId: 228769,
        value: 'University of Texas, Arlington',
        count: 260,
      },
      {
        unitId: 228778,
        value: 'University of Texas, Austin',
        count: 299,
      },
      {
        unitId: 228787,
        value: 'University of Texas, Dallas',
        count: 279,
      },
      {
        unitId: 228796,
        value: 'University of Texas, El Paso',
        count: 258,
      },
      {
        unitId: 229027,
        value: 'University of Texas, San Antonio',
        count: 230,
      },
      {
        unitId: 206084,
        value: 'University of Toledo',
        count: 147,
      },
      {
        unitId: 207971,
        value: 'University of Tulsa',
        count: 136,
      },
      {
        unitId: 230764,
        value: 'University of Utah',
        count: 227,
      },
      {
        unitId: 230764,
        value: 'University of Vermont',
        count: 265,
      },
      {
        unitId: 234076,
        value: 'University of Virginia',
        count: 260,
      },
      {
        unitId: 236948,
        value: 'University of Washington',
        count: 259,
      },
      {
        unitId: 240268,
        value: 'University of Wisconsin, Eau Claire',
        count: 318,
      },
      {
        unitId: 240444,
        value: 'University of Wisconsin, Madison',
        count: 265,
      },
      {
        unitId: 240453,
        value: 'University of Wisconsin, Milwaukee',
        count: 282,
      },
      {
        unitId: 240727,
        value: 'University of Wyoming',
        count: 98,
      },
      {
        unitId: 230728,
        value: 'Utah State University',
        count: 253,
      },
      {
        unitId: 221999,
        value: 'Vanderbilt University',
        count: 260,
      },
      {
        unitId: 197133,
        value: 'Vassar College',
        count: 123,
      },
      {
        unitId: 234030,
        value: 'Virginia Commonwealth University',
        count: 162,
      },
      {
        unitId: 233921,
        value: 'Virginia Tech University',
        count: 223,
      },
      {
        unitId: 199847,
        value: 'Wake Forest University',
        count: 134,
      },
      {
        unitId: 234207,
        value: 'Washington and Lee University',
        count: 167,
      },
      {
        unitId: 236939,
        value: 'Washington State University',
        count: 259,
      },
      {
        unitId: 179867,
        value: 'Washington University in St Louis',
        count: 263,
      },
      {
        unitId: 172644,
        value: 'Wayne State University',
        count: 274,
      },
      {
        unitId: 168218,
        value: 'Wellesley College',
        count: 229,
      },
      {
        unitId: 130697,
        value: 'Wesleyan University',
        count: 134,
      },
      {
        unitId: 238032,
        value: 'West Virginia University',
        count: 260,
      },
      {
        unitId: 172699,
        value: 'Western Michigan University',
        count: 252,
      },
      {
        unitId: 168281,
        value: 'Wheaton College',
        count: 92,
      },
      {
        unitId: 168342,
        value: 'Williams College',
        count: 221,
      },
      {
        unitId: 168421,
        value: 'Worcester Polytechnic Institute',
        count: 130,
      },
      {
        unitId: 206604,
        value: 'Wright State University',
        count: 256,
      },
      {
        unitId: 130794,
        value: 'Yale University',
        count: 286,
      },
    ],
  ],
  demographics: ['school'],
}

export default schoolTotalCountData
