import React, { useState } from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

// Styles
import './Navigation.scss'

// Motion
import { motion } from 'framer-motion'

/* TODO: Hamburger Menu */

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false)

  return (
    <div className={navOpen ? 'open-nav-container' : ''}>
      <div className="container pt-3 pb-1">
        <div className="logos is-relative nav-logos">
          <a target="_blank" rel="noreferrer" href="https://www.collegepulse.com">
            <img src="/assets/cp.svg" alt="College Pulse" width="170" className="ml-4" />
          </a>

          <a target="_blank" rel="noreferrer" href="https://www.thefire.org/">
            <img
              src="/assets/fire.svg"
              alt="Fire Organization"
              width="80"
              className="ml-6"
              style={{ marginTop: -2 }}
            />
          </a>
        </div>
      </div>
      <nav className={`navbar container mb-3 ${navOpen ? 'navbar-open' : ''}`}>
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <h1 className="title mobile-title-shrink">2024 College Free Speech Rankings</h1>
          </Link>

          <Link
            to="#"
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
            onClick={() => setNavOpen(p => !p)}
          >
            <motion.span
              animate={!navOpen ? { rotate: 0, y: 0 } : { rotate: 45, y: 5 }}
              aria-hidden="true"
            ></motion.span>
            <motion.span
              animate={!navOpen ? { scaleX: 1 } : { scaleX: 0 }}
              aria-hidden="true"
            ></motion.span>
            <motion.span
              animate={!navOpen ? { rotate: 0, y: 0 } : { rotate: -45, y: -5 }}
              aria-hidden="true"
            ></motion.span>
          </Link>
        </div>

        <div className={`navbar-menu ${navOpen ? 'force-open-nav' : ''}`}>
          <div className="navbar-end">
            <NavLink
              activeClassName="is-active has-text-weight-bold"
              exact
              to="/rank"
              className="navbar-item"
              onClick={() => setNavOpen(false)}
            >
              Ranking
            </NavLink>
            <NavLink
              activeClassName="is-active has-text-weight-bold"
              exact
              to="/rank/compare"
              className="navbar-item"
              onClick={() => setNavOpen(false)}
            >
              Compare
            </NavLink>
            <NavLink
              activeClassName="is-active has-text-weight-bold"
              exact
              to="/rank/methodology"
              className="navbar-item"
              onClick={() => setNavOpen(false)}
            >
              Methodology
            </NavLink>
            <a
              className="button is-success is-small mt-2 ml-4 is-radiusless is-size-6 no-mobile"
              style={{ top: -2 }}
              href="/rank/explore"
            >
              Explore The Data{' '}
              <FontAwesomeIcon className="ml-3 is-size-7" icon={faExternalLinkAlt} />
            </a>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navigation