import React, { useEffect, useState, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDollarSign, faSearch, faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import states from './states'
import _ from 'lodash'
import ReactGA from 'react-ga'

// Style
import './Sort.scss'

// Slider
import bulmaSlider from '../../../node_modules/bulma-slider/dist/js/bulma-slider.min'

// Animation
import { motion, AnimatePresence } from 'framer-motion'

const Sort = ({
  searchInput,
  setSearchInput,
  filterData,
  setFilterData,
  openData,
  setOpenData,
}) => {
  // Attach the slider to it's component
  useEffect(() => {
    bulmaSlider.attach()
  }, [])

  // Handle checkboxes
  const handleCheck = (type, toggledFilterValues) => {
    ReactGA.event({
      category: 'Filter',
      action: 'Applied a filter',
      label: `${type}: ${toggledFilterValues}`,
    })

    // first element of array is just `type`. Rest are filters
    const existingFilters = filterData[type].slice(1)

    // if toggledFilterValues is among existingFilters, remove it. Otherwise, add it
    const newFilters = _.xorWith(existingFilters, toggledFilterValues, _.isEqual)

    // if we've just removed the only filter from this category, then we set the category to an empty array
    if (!newFilters.length) {
      return setFilterData({
        ...filterData,
        [type]: [],
      })
    }

    // otherwise, we set it to [type, ...] with all filters in the array
    setFilterData({
      ...filterData,
      [type]: [type, ...newFilters],
    })
  }

  const isOptionChecked = (type, filterValues) => {
    // first element of array is just `type`. Rest are filters
    const existingFilters = filterData[type].slice(1)

    const intersection = _.intersectionWith(existingFilters, filterValues, _.isEqual)

    // option should be checked if all filterValues are in existingFilters
    // check this by making sure intersection is equal to filterValues
    return _.isEqual(_.sortBy(intersection), _.sortBy(filterValues))
  }

  // Distance Logic
  const [currentZip, setCurrentZip] = useState('')
  const [currentDistance, setCurrentDistance] = useState('250')

  // Debounced states to prevent excessive API calls or other operations
  const [debouncedZip, setDebouncedZip] = useState('')
  const [debouncedDistance, setDebouncedDistance] = useState('')

  // Create the debounced functions using useCallback to memoize them
  const debouncedSetZip = useCallback(_.debounce(zip => setDebouncedZip(zip), 1000), [])
  const debouncedSetDistance = useCallback(_.debounce(distance => setDebouncedDistance(distance), 1000), [])

  // Update debounced values on state change
  useEffect(() => {
    debouncedSetZip(currentZip)
    return debouncedSetZip.cancel // cleanup function to cancel the debounced function on unmount
  }, [currentZip, debouncedSetZip])

  useEffect(() => {
    debouncedSetDistance(currentDistance)
    return debouncedSetDistance.cancel // cleanup function to cancel the debounced function on unmount
  }, [currentDistance, debouncedSetDistance])

  // Update the filter when debounced values change
  useEffect(() => {
    setFilterData(prevFilterData => ({
      ...prevFilterData,
      withinDistance: debouncedZip ? ['withinDistance', debouncedZip, debouncedDistance] : [],
    }))
  }, [debouncedDistance, debouncedZip, setFilterData])

  // Custom Components
  const distanceFromZip = i => {
    return (
      <React.Fragment key={i}>
        <div className="filter">
          <div
            className="level mb-0 is-dropdown"
            onClick={() =>
              setOpenData({
                ...openData,
                withinDistanceOpen: !openData['withinDistanceOpen'],
              })
            }
          >
            <div className="level-left">
              <h3 className="has-text-weight-bold">Distance from ZIP Code</h3>
            </div>
            <div className="level-right py-4 px-4">
              <motion.div animate={{ rotate: openData.withinDistanceOpen ? '-90deg' : '0deg' }}>
                <FontAwesomeIcon icon={faAngleLeft} />
              </motion.div>
            </div>
          </div>

          <AnimatePresence>
            {openData.withinDistanceOpen && (
              <motion.div
                animate={{ height: '100%', opacity: 1 }}
                initial={{ height: 0, opacity: 0 }}
                exit={{ height: 0, opacity: 0, transition: { duration: 0.1 } }}
                className="pb-3"
              >
                <div className="control mr-3" style={{ zIndex: 2 }}>
                  <div className="field">
                    <div className="label has-text-weight-bold is-size-7">Zip Code</div>
                    <input
                      className="input"
                      type="text"
                      value={currentZip}
                      onChange={e => setCurrentZip(e.target.value)}
                    />
                  </div>
                </div>
                <AnimatePresence>
                  {filterData.withinDistance[1] && (
                    <motion.div
                      className="control mt-0 pt-0"
                      initial={{ opacity: 0, y: '-100%' }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: '-100%', transition: { duration: 0.08 } }}
                    >
                      <div className="label has-text-weight-bold is-size-7 mb-0 pb-0 mt-4">
                        Miles
                      </div>
                      <div style={{ top: '-10px' }} className="level mt-0 pt-0 is-relative">
                        <input
                          className="level-left mr-3 no-arrows"
                          type="number"
                          value={currentDistance}
                          onChange={e => setCurrentDistance(e.target.value)}
                          style={{
                            width: 50,
                            borderRadius: '5px',
                            padding: '5px',
                          }}
                        />
                        <div
                          className="level-right is-relative mr-3"
                          style={{ flex: 1, height: 'auto' }}
                        >
                          <input
                            id="sliderWithValue"
                            className="slider is-fullwidth no-arrows"
                            min="0"
                            max="500"
                            step="1"
                            type="range"
                            value={currentDistance}
                            onChange={e => setCurrentDistance(e.target.value)}
                          />
                          <span
                            style={{ width: `${currentDistance / 5}%` }}
                            className="sliderLeftBar"
                          />
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="divider" />
      </React.Fragment>
    )
  }

  const maxiumumTuition = () => {
    return (
      <div className="filter mb-5">
        <div className="level mb-0">
          <div className="level-left">
            <h3 className="has-text-weight-bold">Maximum Tuition</h3>
          </div>
          <div className="level-right py-4 px-4"></div>
        </div>

        <AnimatePresence>
          {openData.tuitionOpen && (
            <motion.div
              animate={{ height: '100%', opacity: 1 }}
              initial={{ height: '100%', opacity: 1 }}
              exit={{ height: 0, opacity: 0, transition: { duration: 0.1 } }}
              className="field"
            >
              <p className="control has-icons-left mr-3">
                <input
                  className="input"
                  value={filterData.tuition[1]}
                  onChange={e => {
                    setFilterData({
                      ...filterData,
                      tuition: e.target.value ? ['tuition', +e.target.value || null] : [],
                    })
                  }}
                  type="number"
                  placeholder="50,000"
                />
                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faDollarSign} />
                </span>
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    )
  }

  const possibleFilters = [
    {
      title: 'Conference',
      apiKey: 'conference',
      options: [
        { label: 'A-10', value: ['A-10'] },
        { label: 'AAC', value: ['AAC'] },
        { label: 'ACC', value: ['ACC'] },
        { label: 'America East Conference', value: ['America East Conference'] },
        { label: 'Big 10', value: ['Big 10'] },
        { label: 'Big 12', value: ['Big 12'] },
        { label: 'Big East', value: ['Big East'] },
        { label: 'Big West', value: ['Big West'] },
        { label: 'CAA', value: ['CAA'] },
        { label: 'Centennial Conference', value: ['Centennial Conference'] },
        { label: 'FBS Independent', value: ['FBS Independent'] },
        { label: 'Ivy League', value: ['Ivy League'] },
        { label: 'Liberty League', value: ['Liberty League'] },
        { label: 'MAC', value: ['MAC'] },
        { label: 'MW', value: ['MW'] },
        { label: 'MWC', value: ['MWC'] },
        { label: 'NCAC', value: ['NCAC'] },
        { label: 'NESCAC', value: ['NESCAC'] },
        { label: 'NEWMAC', value: ['NEWMAC'] },
        { label: 'PAC', value: ['PAC'] },
        { label: 'PAC-12', value: ['PAC-12'] },
        { label: 'Patriot League', value: ['Patriot League'] },
        { label: 'SEC', value: ['SEC'] },
        { label: 'UAA', value: ['UAA'] },
        { label: 'Liberal Arts', value: ['Liberal Arts'] },
        { label: 'Other', value: ['Other'] },
      ],
    },
    {
      title: 'Distance from Zip',
      apiKey: 'conference',
      options: [],
      customComponent: e => distanceFromZip(e),
    },
    {
      title: 'State',
      apiKey: 'targetStates',
      options: states.map(state => ({ label: state, value: [state] })),
    },
    {
      title: 'School Type',
      apiKey: 'controlOfInstitution',
      options: [
        { label: 'Public', value: ['Public'] },
        { label: 'Private', value: ['Private not-for-profit', 'Private for-profit'] },
      ],
    },
    {
      title: 'Enrollment Size',
      apiKey: 'undergraduateEnrollment',
      options: [
        { label: '0–1,999', value: [[0, 1999]] },
        { label: '2,000–4,999', value: [[2000, 4999]] },
        { label: '5,000–9,999', value: [[5000, 9999]] },
        { label: '10,000–24,999', value: [[10000, 24999]] },
        { label: '25,000–49,999', value: [[25000, 49999]] },
        { label: '50,000+', value: [[50000, 10 ** 6]] },
      ],
    },
    {
      title: 'Speech Code',
      apiKey: 'speechCode',
      options: [
        { label: 'Green', value: ['Green'] },
        { label: 'Yellow', value: ['Yellow'] },
        { label: 'Red', value: ['Red'] },
      ],
    },
    {
      title: 'Majority Viewpoint',
      apiKey: 'viewpoint',
      options: [
        { label: 'Conservative', value: ['Conservative'] },
        { label: 'Liberal', value: ['Liberal']},
      ],
    },
    {
      title: 'Maximum Tuition',
      apiKey: 'tuition',
      options: [],
      customComponent: () => maxiumumTuition(),
    },
  ]

  return (
    <aside className="mr-4 pr-2 sort-container pt-4">
      <div className="level my-0 py-0">
        <div className="level-left">
          <h2 className="title has-text-weight-bold is-5 mb-2 mt-3">Filters</h2>
        </div>
      </div>

      <div className="divider" />

      <div className="field is-rounded py-3 mb-0">
        <div className="control has-icons-left">
          <input
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            className="input"
            type="text"
            placeholder="Search for a school"
          />
          <span className="icon is-small is-left">
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </div>
      </div>

      <div className="divider" />

      {possibleFilters.map(
        (pos, i) =>
          (pos.customComponent && pos.customComponent(i)) || (
            <React.Fragment key={i}>
              <div
                className={`filter ${pos.disabled ? 'disableFilter' : ''}`}
                style={{ position: 'relative' }}
                key={i}
              >
                <div
                  className="level mb-0 is-dropdown"
                  onClick={() =>
                    setOpenData({
                      ...openData,
                      [pos.apiKey + 'Open']: !openData[`${pos.apiKey}Open`],
                    })
                  }
                >
                  <div className="level-left">
                    <h3 className="has-text-weight-bold">{pos.title}</h3>
                  </div>
                  <div className="level-right py-4 px-4">
                    {/* TODO: Do we add collapse effect to filters? */}
                    <motion.div
                      animate={{ rotate: openData[`${pos.apiKey}Open`] ? '90deg' : '-90deg' }}
                    >
                      <FontAwesomeIcon icon={faAngleLeft} />
                    </motion.div>
                  </div>
                </div>
                <AnimatePresence>
                  {openData[`${pos.apiKey}Open`] && (
                    <motion.div
                      style={{ transformOrigin: 'center top', position: 'relative' }}
                      animate={{ height: '100%', opacity: 1 }}
                      initial={{ height: 0, opacity: 0 }}
                      exit={{ height: 0, opacity: 0, transition: { duration: 0.1 } }}
                      className="control mt-0 pt-0 cp-radio pb-1"
                    >
                      {pos.options.map(opt => (
                        <label
                          key={opt.value}
                          className="radio pl-0 ml-0 mb-4 is-2"
                          style={{ width: '100%' }}
                        >
                          <input
                            onChange={() => handleCheck(pos.apiKey, opt.value, pos.apiKey)}
                            checked={isOptionChecked(pos.apiKey, opt.value)}
                            className="mr-3"
                            type="checkbox"
                            name="schooltype"
                          />
                          <span className="custom-radio checkbox"></span>
                          <div className="level">
                            <div className="level-left">{opt.label}</div>
                          </div>
                        </label>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

              <div className="divider" />
            </React.Fragment>
          )
      )}

      <div className="divider" />
    </aside>
  )
}
export default Sort
