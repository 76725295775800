import React, { useState, useEffect, useCallback } from 'react'
import Select from 'react-select'
import { getCompareSchools, getSchool } from '../api/schools'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

// Components
import SchoolOverview from '../Components/SchoolOverview/SchoolOverview'
import ScrollContainer from 'react-indiana-drag-scroll'
import ShareComponent from '../Components/ShareComponent/ShareComponent'

// Styles
import '../Styles/Compare.scss'

const Compare = () => {
  let history = useHistory()

  const [searchInput, setSearchInput] = useState('')
  const [availableSchools, setAvailableSchools] = useState([])
  const [selectedSchools, setSelectedSchools] = useState([])

  const removeSchool = slug => {
    setSelectedSchools(prevState => prevState.filter(school => school.slug !== slug))
    if (selectedSchools.length < 2) {
      history.push('/rank/compare')
    } else {
      history.push({
        pathname: '/rank/compare',
        search: `?schools=${JSON.stringify(
          _.compact(selectedSchools.map(school => (school.slug !== slug ? school.slug : null)))
        )}`,
      })
    }
  }

  const addSchool = useCallback(async (slug, ignore) => {
    let newSchool = await getSchool(slug)
    setSelectedSchools(prevState => {
      // Make sure this school isn't already in the array before adding it
      if (prevState.some(school => school.slug === slug)) return prevState
      return [...prevState, newSchool]
    })
    setSearchInput('')
  
    if (!ignore) {
      history.push({
        pathname: '/rank/compare',
        search: `?schools=${JSON.stringify([...selectedSchools.map(school => school.slug), slug])}`,
      })
    }
  }, [history, selectedSchools])

  useEffect(() => {
    const getSchoolList = async () => {
      const data = await getCompareSchools(searchInput)

      setAvailableSchools(data.map(e => ({ value: e.slug, label: e.institutionName })))
    }

    if (searchInput.length > 3) {
      getSchoolList()
    }
  }, [searchInput, setAvailableSchools])

  useEffect(() => {
    let params = new URLSearchParams(window.location.search)
    let schools = JSON.parse(params.get('schools'))
    if (schools) {
      schools.forEach(school => {
        addSchool(school, true)
      })
    }
  }, [addSchool])

  return (
    <section className="section my-5">
      <div className="box py-4 px-4 container" style={{ maxWidth: 400 }}>
        <h3 className="subtitle mb-1 has-text-weight-bold">Add a school</h3>
        <p>
          Compare colleges side-by-side to find the right school for you! Add up to four schools to
          compare free-speech ranking, enrollment size, tuition, and more.
        </p>
        <ShareComponent />
        <div className="divider mb-4" />
        <div className="field is-rounded" style={{ overflow: 'visible' }}>
          <div className="control has-icons-left" style={{ overflow: 'visible' }}>
            {selectedSchools.length < 4 ? (
              <Select
                type="search"
                onInputChange={e => setSearchInput(e)}
                value=""
                inputValue={searchInput}
                options={availableSchools}
                onChange={e => addSchool(e.value)}
                styles={{ menu: styles => ({ ...styles, zIndex: 999 }) }}
                placeholder="Search for a school"
                autoFocus={true}
                noOptionsMessage={() =>
                  'We couldn\'t find a school, try searching something different!'
                }
              />
            ) : (
              <p>
                <em>Remove a school to add another!</em>
              </p>
            )}
          </div>
        </div>
      </div>
      <ScrollContainer className="scroll-container" vertical={false} horizontal={true}>
        <div className="columns fix-scrolling">
          {selectedSchools.map(school => (
            <SchoolOverview
              key={school.institutionName}
              school={school}
              removeSchool={removeSchool}
            />
          ))}
        </div>
      </ScrollContainer>
    </section>
  )
}

export default Compare