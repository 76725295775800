import React from 'react'
import _ from 'lodash'

// Animation
import { motion, AnimatePresence } from 'framer-motion'

// Styles
import './TextLoaders.scss'

/* TODO: validateDomNesting does not want div's and article's to be children of p */

export const loader = {
  p: ({ children, width, className, lines = 1, lineHeight = '1em' }) => (
    <div
      style={{ minHeight: `calc(1.25em * ${lines})` }}
      className={`is-relative ${className || ''}`}
    >
      <AnimatePresence>
        {!children &&
          _.times(lines, i => (
            <motion.span
              initial={{ opacity: 1 }}
              animate={{ opacity: 1 }}
              key={i}
              exit={{ opacity: 0 }}
              className="empty-text"
              style={{
                width: `calc(${width} * ${Math.random() + 0.5})`,
                maxWidth: '95%',
                top: `calc((${lineHeight} + 0.5em) * ${i})`,
                height: lineHeight,
              }}
            />
          ))}
      </AnimatePresence>
      {children && children}
    </div>
  ),
  h1: ({ children, width, className }) => (
    <h1 style={{ minHeight: '1em' }} className={`is-relative ${className || ''}`}>
      <AnimatePresence>
        {!children && (
          <motion.span
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="empty-text"
            style={{ width }}
          />
        )}
      </AnimatePresence>
      {children && children}
    </h1>
  ),
  h2: ({ children, width, className }) => (
    <h2 style={{ minHeight: '1em' }} className={`is-relative ${className || ''}`}>
      <AnimatePresence>
        {!children && (
          <motion.span
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="empty-text"
            style={{ width }}
          />
        )}
      </AnimatePresence>
      {children && children}
    </h2>
  ),
}
