import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import ReactGA from 'react-ga'

// Slider
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import SliderCard from '../Components/SliderCard/SliderCard'

// Styles
import '../Styles/Landing.scss'

// Quotes
import topQuotes from '../Data/topQuotes'

// Components
import ShareComponent from '../Components/ShareComponent/ShareComponent'

const Landing = () => {
  const [windowWidth, setWindowWidth] = useState(0)
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    handleResize()

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: windowWidth < 1023 ? 1 : 2,
    slidesToScroll: 1,
  }

  return (
    <>
      <section className="section">
        <div className="hero-bg-image no-mobile">
          <img src="/assets/students.svg" alt="Illustration" />
        </div>
        <div className="hero my-5">
          <div className="hero-body">
            <div className="container is-flex">
              <div className="is-mw-60 hero-text-content">
                <div className="mb-5">
                  <div className="logos is-relative">
                    <a target="_blank" rel="noreferrer" href="https://www.collegepulse.com">
                      <img
                        src="/assets/cp.svg"
                        alt="College Pulse"
                        width="170"
                        className="ml-4 logo-cp"
                      />
                    </a>

                    <a target="_blank" rel="noreferrer" href="https://www.thefire.org/">
                      <img
                        src="/assets/fire.svg"
                        alt="Fire Organization"
                        width="80"
                        className="ml-6 logo-fire"
                        style={{ marginTop: -2 }}
                      />
                    </a>
                  </div>
                </div>
                <h1 className="title is-1 has-text-weight-bold">
                  2024 College Free Speech Rankings
                </h1>
                <div className="divider mb-5" style={{ backgroundColor: '#50E3C2', height: 2 }} />
                <p className="mt-3">
                  Presented by College Pulse and the Foundation for Individual Rights and Expression
                  (FIRE), the 2024 College Free Speech Rankings is a comprehensive comparison of the
                  student experience of free speech on their campuses. These rankings are based on
                  the voices of over 55,000 currently enrolled students at over 250 colleges and are
                  designed to help parents and prospective students choose the right college.
                </p>
                <div>
                  <Link
                    className="hero-button button is-success mt-6 has-text-weight-bold"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Click',
                        action: 'Clicked "See the rankings"',
                        label: 'Click: See the rankings',
                      })
                    }}
                    to="/rank"
                  >
                    See the rankings
                  </Link>
                  <a
                    className="hero-button ml-4 button is-success mt-6 has-text-weight-bold is-light"
                    href="https://reports.collegepulse.com/college-free-speech-rankings-2023-2024"
                    target="_blank"
                    onClick={() => {
                      ReactGA.event({
                        category: 'Click',
                        action: 'Clicked "Download the report"',
                        label: 'Click: Download the report',
                      })
                    }}
                    rel="noreferrer"
                  >
                    Download the report
                  </a>
                </div>
                <div className="mt-5">
                  <ShareComponent position="left" />
                </div>
                <img
                  className="only-mobile mobile-hero-image"
                  src="/assets/header-mobile.svg"
                  alt="Illustration"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-white-ter my-6 mobile-container">
        <div className="container py-6">
          <h2 className="subtitle is-center mb-5 pb-2">Featured Universities</h2>
          <div className="mobile-companies">
            <img alt="School" style={{ height: 65 }} src="/assets/schools/uchicago.png" />

            <img alt="School" style={{ height: 45 }} src="/assets/schools/harvard.png" />

            <img alt="School" style={{ height: 50 }} src="/assets/schools/yale.png" />

            <img alt="School" style={{ height: 50 }} src="/assets/schools/princeton.png" />

            <img alt="School" style={{ height: 50 }} src="/assets/schools/stanford.png" />

            <img alt="School" style={{ height: 55 }} src="/assets/schools/berkeley.png" />
          </div>
        </div>
      </section>

      <section
        className="section container px-0 mt-6 mobile-container"
        style={{ overflow: 'visible' }}
      >
        <h1 className="title mt-6 pt-6 mb-5 pb-2">What students are saying</h1>
        <Slider
          {...settings}
          className="columns is-flex is-scroller"
          style={{ overflow: 'visible', marginLeft: '-1rem', marginRight: '-1rem' }}
        >
          {topQuotes.map((quote, i) => (
            <SliderCard key={i} year={quote.graduationYear} school={quote.school}>
              {quote.quote.replace(/"/g, "'")}
            </SliderCard>
          ))}
        </Slider>
      </section>

      <section className="section mt-6 pt-6 approach">
        <div className="container landing-contain">
          <h1 className="title has-text-centered is-2 mt-6">Our Approach</h1>

          <div className="columns">
            <div className="column is-one-third">
              <img alt="Illustration" width="100%" src="/assets/cpi.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pl-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Overall Score</h1>
              <p>
              To create an overall score for each college, we first summed the following student subcomponents: “Comfort Expressing Ideas,” “Mean Tolerance,” “Disruptive Conduct,” “Administrative Support,” and “Openness.” Then, we subtracted the “Tolerance Difference.” By including the “Mean Tolerance” (as opposed to including “Tolerance for Liberal Speakers” and “Tolerance for Conservative Speakers” separately) and subtracting the “Tolerance Difference,” the score accounted for the possibility that ideologically homogeneous student bodies may result in a campus that appears to have a strong culture of free expression but is actually hostile to the views of an ideological minority — whose views students may almost never encounter on campus. Then, to further account for the speech climate on an individual campus, we incorporated behavioral components. First, we applied bonuses and penalties to a school based on the outcome of speech controversies on campus involving students, professors, and invited speakers. Then, each school's score was adjusted based on their FIRE Spotlight Rating.
              </p>
            </div>
          </div>

          <div className="columns mt-6">
            <div className="column is-one-third only-mobile">
              <img alt="Illustration" width="100%" src="/assets/openness.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pr-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Openness</h1>
              <p>
                Openness measures the student’s perceived ability to have difficult conversations on
                campus. Students were asked to identify whether they would feel open to discussing a
                range of issues on their campus. Topics included fifteen traditionally
                difficult-to-discuss topics, including race, transgender issues, abortion, and gun
                control. When a college’s students identify fewer items as “difficult to discuss,”
                this indicates a more open environment for the free exchange of ideas.
              </p>
            </div>

            <div className="column is-one-third no-mobile">
              <img alt="Illustration" width="100%" src="/assets/openness.svg" />
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-third">
              <img alt="Illustration" width="100%" src="/assets/liberalspeakers.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pl-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Tolerance for Liberal Speakers</h1>
              <p>
                Tolerance for Liberal Speakers measures student willingness to allow controversial
                speech on their campus. Students were asked whether they would support or oppose
                speakers with a number of controversial views being allowed to come to speak on
                their campus. Tolerance for Liberal Speakers included four topics such as “Religious
                liberty is used as an excuse to discriminate against gays and lesbians” or “White
                people are collectively responsible for structural racism and use it to protect
                their privilege.” When more students indicate that they would be willing to let such
                a person speak on campus, this indicates a more open free-speech climate.
              </p>
            </div>
          </div>

          <div className="columns mt-6">
            <div className="column is-one-third only-mobile">
              <img alt="Illustration" width="100%" src="/assets/conservativespeakers.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pr-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Tolerance for Conservative Speakers</h1>
              <p>
                Tolerance for Conservative Speakers measures student willingness to allow
                controversial speech on their campus. Students were asked whether they would support
                or oppose speakers with a number of controversial views being allowed to come to
                speak on their campus. Tolerance for Conservative Speakers included four topics such
                as “Black Lives Matter is a hate group,” and “The lockdown orders issued in response
                to the coronavirus have infringed on our personal liberties.” When more students
                indicate that they would be willing to let such a person speak on campus, this
                indicates a more open free-speech climate.
              </p>
            </div>

            <div className="column is-one-third no-mobile">
              <img alt="Illustration" width="100%" src="/assets/conservativespeakers.svg" />
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-third">
              <img width="100%" src="/assets/admin.svg" alt="Admin Artwork" />
            </div>
            <div
              className="column is-center is-two-thirds pl-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Administrative Support for Free Speech</h1>
              <p>
                Administrative Support for Free Speech measures student perception of their
                college’s support for free speech based on responses from two survey questions
                including, “How clear is it to you that your college administration protects free
                speech on campus?” Options were presented on a five point scale ranging from
                “extremely clear” to “not at all clear”. Second, students were asked, “If a
                controversy over offensive speech were to occur on your campus, how likely is it
                that the administration would defend the speaker's right to express their views?”
                Options ranged from “extremely likely” to “not at all likely.” Students’ perception
                that administrators are clear about protecting speech and that they would defend an
                “offensive” speaker’s rights indicates a more open free-speech climate.
              </p>
            </div>
          </div>

          <div className="columns mt-6">
            <div className="column is-one-third only-mobile">
              <img alt="Illustration" width="100%" src="/assets/selfcensor.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pr-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Comfort Expressing Ideas</h1>
              <p>
                Students were asked how comfortable or uncomfortable they would feel doing
                activities on their campus such as “Publicly disagreeing with a professor about a
                controversial topic” or “Expressing your views on a controversial political topic
                during an in-class discussion.” Options ranged from “very comfortable” to “very
                uncomfortable.” Answers with more comfort expressing ideas received higher scores.
                Additionally, this item includes the response to the question, “On your campus, how
                often have you felt that you could not express your opinion on a subject because of
                how students, a professor, or the administration would respond?” Options ranged on a
                five-point scale from “never” to “very often.” Less frequent indication of an
                inability to share feelings received a higher score.
              </p>
            </div>

            <div className="column is-one-third no-mobile">
              <img alt="Illustration" width="100%" src="/assets/selfcensor.svg" />
            </div>
          </div>

          <div className="columns">
            <div className="column is-one-third">
              <img width="100%" src="/assets/disruptive.svg" alt="Disruptive Conduct Artwork" />
            </div>
            <div
              className="column is-center is-two-thirds pl-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Disruptive Conduct</h1>
              <p>
                Students were asked how acceptable or unacceptable different kinds of controversial
                activities were to protest a campus speaker including, “Shouting down a speaker or
                trying to prevent them from speaking on campus,” “Blocking other students from
                attending a campus speech,” and “Using violence to stop a campus speech.” Answer
                options ranged from “always acceptable” to “never acceptable.” Lower acceptance of
                controversial protest behavior received a higher score.
              </p>
            </div>
          </div>

          <div className="columns mt-6">
          <div className="column is-one-third only-mobile">
              <img alt="Illustration" width="100%" src="/assets/speech.svg" />
            </div>
            <div
              className="column is-center is-two-thirds pr-6"
              style={{ flexDirection: 'column', alignItems: 'flex-start' }}
            >
              <h1 className="title">Speech Code Rating</h1>
              <p>
                Speech Code measures whether college policies restrict student speech that is
                protected by the First Amendment. Four ratings are possible: Warning, Red, Yellow,
                or Green. Each year, the Foundation for Individual Rights and Expression (FIRE), a
                national free-speech organization, rates more than 475 institutions nationwide to
                determine whether college policies restrict student speech that is protected by the
                First Amendment. “Green” ratings indicate more open environments for free speech and
                give a college a higher score, but “Warning” and “Red” ratings show that colleges
                have policy environments that dampen free speech, and college scores are penalized.
              </p>
            </div>

            <div className="column is-one-third no-mobile">
              <img alt="Illustration" width="100%" src="/assets/speech.svg" />
            </div>
          </div>
        </div>
      </section>

      <section className="is-center section mb-6 pb-6">
        <Link
          onClick={() => {
            ReactGA.event({
              category: 'Click',
              action: 'Clicked "See the rankings"',
              label: 'Click: See the rankings',
            })
          }}
          className="button is-success mt-6 has-text-weight-bold"
          to="/rank"
        >
          See the rankings
        </Link>
      </section>

      <section className="section has-background-success mt-6">
        <div
          className="container py-6"
          style={{ maxWidth: 640, marginLeft: 'auto', marginRight: 'auto' }}
        >
          <h1 className="title has-text-light is-center has-text-centered">Stay In The Know.</h1>
          <h4 className="subtitle has-text-light is-center has-text-centered">
            Get updates from the College Free Speech team including newsletters, rankings
            announcements, and new features.
          </h4>

          <div id="hubspot_form" className="px-5 py-5" />
        </div>
      </section>
    </>
  )
}

export default Landing
