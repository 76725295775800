import React, { useState, useEffect, useRef, useCallback } from 'react'
import _ from 'lodash'
import ReactGA from 'react-ga'

// API
import { getAll } from '../api/schools'

// Components
import Sort from '../Components/Sort/Sort'
import Table from '../Components/Table/Table'
import ShareComponent from '../Components/ShareComponent/ShareComponent'

// Handle the pagination
let currentPage
let requestNonce = 0

const Rank = () => {
  // Filter mobile
  const [filterMobile, setFilterMobile] = useState(false)
  useEffect(() => {
    filterMobile
      ? document.documentElement.classList.add('no-flow')
      : document.documentElement.classList.remove('no-flow')
  }, [filterMobile])

  // Force loading bars
  const [loading, setLoading] = useState(true)

  // Input
  const [searchInput, setSearchInputData] = useState('')
  const setSearchInput = i => {
    debounce(i)
    setSearchInputData(i)
  }

  // Schools State
  const [schools, setSchools] = useState([])
  const [warningSchools, setWarningSchools] = useState([])

  // Tell the paginator to stop trying to call api.
  const [stopLoading, setStopLoading] = useState(false)

  // Sorting
  const [activeCategory, setActiveCategoryData] = useState('overall')

  const setActiveCategory = sort => {
    setActiveCategoryData(sort)
  }

  const columnOrder = [
    'CSI',
    'percentLiberal',
    'percentModerate',
    'percentConservative',
    'tolerance',
    'openness',
    'adminSupport',
    'expression',
    'speechCode',
    'speechClimate',
  ]

  // Column Sorting
  const [currentlySortingBy, setCurrentlySortedBy] = useState({
    value: 'overall',
    direction: 'desc',
  })

  const handleColumnSorting = column => {
    if (column !== currentlySortingBy.value) {
      setCurrentlySortedBy({ value: column, direction: 'asc' })
    } else if (currentlySortingBy.direction === 'asc') {
      setCurrentlySortedBy({ value: column, direction: 'desc' })
    } else {
      setCurrentlySortedBy({ value: 'overall', direction: 'desc' })
    }
  }

  // Store The Filters
  // We store filters as an object, then only send the Object.values()
  const [filterData, setFilterData] = useState({
    withinDistance: [],
    tuition: [],
    conference: [],
    targetStates: [],
    controlOfInstitution: [],
    undergraduateEnrollment: [],
    speechCode: [],
    viewpoint: [],
  })
  const [openData, setOpenData] = useState({
    withinDistanceOpen: true,
    tuitionOpen: true,
    conferenceOpen: false,
    targetStatesOpen: false,
    controlOfInstitutionOpen: true,
    undergraduateEnrollmentOpen: false,
    speechCodeOpen: true,
    viewpointOpen: true,
  })

  // Header options
  const headerOptions = {
    overall: {
      graphic: '/assets/award.svg',
      title: 'Overall Rankings',
      text: 'The Overall Score combines thirteen unique dimensions to identify the top ranked college campuses for student free speech and open inquiry. The top-ranked colleges have the highest average score among all students surveyed and have the most open environments for free speech. <a class="has-text-success is-underlined" href="/rank/methodology">Read more on how this ranking was calculated.</a>',
    },
  }

  const [debouncedSearchInput, setDebouncedSearchInput] = useState('')

  const debouncedFnRef = useRef(_.debounce(i => setDebouncedSearchInput(i), 500))

  const debounceSetSearch = useCallback(
    inputValue => {
      debouncedFnRef.current(inputValue)
    },
    [] // Again, no dependencies here because setDebouncedSearchInput is stable.
  )

  const debounce = debounceSetSearch

  const prevFilterData = usePrevious(filterData)
  const prevCurrentlySortingBy = usePrevious(currentlySortingBy)
  const prevActiveCategory = usePrevious(activeCategory)
  const prevDebouncedSearch = usePrevious(debouncedSearchInput)

  useEffect(() => {
    const pullData = async (fd, sd, sort, column) => {
      setLoading(true)

      requestNonce++
      const snapshotRequestNonce = requestNonce

      currentPage = 1
      // Get values, filter out empty arrays,
      let newD = await getAll(
        _.filter(Object.values(fd), _.size),
        sd,
        currentPage,
        sort,
        column
      ).catch(err => {
        return []
      })

      ReactGA.event({
        category: 'Table',
        action: 'Pull data',
        label: 'Pull Data Function',
      })

      // a newer request has been made. Ignore this call
      if (snapshotRequestNonce < requestNonce) {
        return
      }

      setSchools(newD.schools)
      setWarningSchools(newD.warningSchools)
      if (_.isEmpty(newD.schools) || newD.schools.length < 10) {
        setStopLoading(true)
        setLoading(false)
        ReactGA.event({
          category: 'Table',
          action: 'Viewed all available schools',
          label: 'All available',
        })
      } else {
        setStopLoading(false)
      }

      setLoading(false)
      currentPage = 2
    }

    const filtersChanged = !_.isEqual(prevFilterData, filterData)
    const sortChanged = !_.isEqual(prevCurrentlySortingBy, currentlySortingBy)
    const activeCategoryChanged = !_.isEqual(prevActiveCategory, activeCategory)
    const searchChanged = !_.isEqual(prevDebouncedSearch, debouncedSearchInput)

    if (filtersChanged || sortChanged || activeCategoryChanged || searchChanged) {
      pullData(filterData, debouncedSearchInput, activeCategory, currentlySortingBy)
    }
  }, [
    filterData,
    activeCategory,
    currentlySortingBy,
    debouncedSearchInput,
    prevFilterData,
    prevCurrentlySortingBy,
    prevActiveCategory,
    prevDebouncedSearch,
  ])

  // Infinite Scroll Pagination
  // const moreSchools = async () => {
  //   // Move up currentPage every call.

  //   if (currentPage > 1 && false) {
  //     const pageToUse = currentPage
  //     currentPage = currentPage + 1
  //     let more = await getAll(
  //       _.filter(Object.values(filterData), _.size),
  //       searchInput,
  //       pageToUse,
  //       activeCategory,
  //       currentlySortingBy
  //     )
  //     ReactGA.event({
  //       category: 'Table',
  //       action: 'Pull data',
  //       label: 'Infinite Scroll Function',
  //     })

  //     // Stop the pagination from loading blank responses.
  //     if (!more.schools || more.schools.length < 10) {
  //       setStopLoading(true)
  //       ReactGA.event({
  //         category: 'Table',
  //         action: 'Viewed all available schools',
  //         label: 'All available',
  //       })
  //     }

  //     // Create a new school array merging both arrays.
  //     setSchools([...schools, ...more.schools])
  //     // currentPage = currentPage + 1
  //   }
  // }

  // const formatNumber = num => {
  //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  // }

  let dataColumns =
    (schools &&
      schools[0] &&
      schools[0].scores &&
      _.sortBy(Object.keys(schools[0].scores), item => columnOrder.indexOf(item))
        .filter(x => x === 'overall' || x === 'speechClimate' || x === currentlySortingBy.value)
        .filter(x => x !== 'rank')) ||
    []

  return (
    <>
      <section className="section has-background-white-ter mx-0 px-0 py-3">
        <div className="container">
          <div className="is-flex">
            <div
              className="header-section-ill no-mobile"
              style={{ width: '100px!important', flexShrink: 0 }}
            >
              <img
                style={{ width: '100px!important', height: 'auto' }}
                alt="Graphic"
                src={headerOptions[activeCategory].graphic}
              />
            </div>
            <div className="is-center header-section-text-column">
              <div>
                <h1 className="title is-4 mb-2 mt-5">{headerOptions[activeCategory].title}</h1>
                <p
                  className="header-section-text"
                  dangerouslySetInnerHTML={{ __html: headerOptions[activeCategory].text }}
                />
                <div className="level">
                  <div className="level-left"></div>
                  <div className="level-right">
                    <ShareComponent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section pt-0 mt-3">
        <div className="container filter-mobile-container" style={{ marginBottom: 0 }}>
          <div className="columns">
            <div
              className={`filter-mobile  ${
                filterMobile ? 'filter-mobile-show' : 'filter-mobile-hide'
              }`}
            >
              <Sort
                filterData={filterData}
                setFilterData={setFilterData}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                openData={openData}
                setOpenData={setOpenData}
              />
            </div>

            <div className="column ranking-colummn">
              {schools ? (
                <Table
                  activeCategory={activeCategory}
                  setActiveCategory={setActiveCategory}
                  stopLoading={stopLoading}
                  // moreSchools={moreSchools}
                  schools={schools}
                  warningSchools={warningSchools}
                  setWarningSchools={setWarningSchools}
                  setSchools={setSchools}
                  currentlySortingBy={currentlySortingBy}
                  setCurrentlySortedBy={setCurrentlySortedBy}
                  handleColumnSorting={handleColumnSorting}
                  loading={loading}
                  dataColumns={dataColumns}
                />
              ) : (
                <div className="column is-center" style={{ marginTop: '20vh' }}>
                  <h1
                    style={{ opacity: 0.5, textAlign: 'center' }}
                    className="is-size-3 has-text-weight-bold"
                  >
                    We're sorry, we couldn't find the school you are looking for.
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <div className="mobile-filter">
        <button
          onClick={() => setFilterMobile(prevstate => !prevstate)}
          className="button is-success is-large"
        >
          {filterMobile ? 'Close Filters' : 'Filter Schools'}
        </button>
      </div>
    </>
  )
}

export default Rank

function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
